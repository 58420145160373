<template>
    <div>
        <!-- <router-link :to="{ name: 'TopRappers' }" class="md:text-3xl text-2xl text-white">
            Rapland
        </router-link> -->
         <div class="lg:text-xl md:text-4xl  text-2xl md:m-4 m-3">
             Payment History
         </div>
         <hr class="mx-4 border-gray-300">
         <div>
             <div class="bleez-text md:m-6 m-6">
                 <p class="lg:text-xl md:text-2xl text-xl">june 2021 - june 2022</p>
                 <p class="lg:text-xl md:text-2xl text-xl">Payment Recieved</p>
             </div>
             <hr class="mx-4 border-gray-300">
         </div>
          <div>
             <div class="bleez-text md:m-6 m-6">
                 <p class="lg:text-xl md:text-2xl text-xl">june 2021 - june 2022</p>
                 <p class="lg:text-xl md:text-2xl text-xl">Payment Recieved</p>
             </div>
             <hr class="mx-4 border-gray-300">
         </div>
          <div>
             <div class="bleez-text md:m-6 m-6">
                 <p class="lg:text-xl md:text-2xl text-xl">june 2021 - june 2022</p>
                 <p class="lg:text-xl md:text-2xl text-xl">Payment Recieved</p>
             </div>
             <hr class="mx-4 border-gray-300">
         </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentHistory',
    setup() {
        
    },
}
</script>